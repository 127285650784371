<template>
  <component :is="commonComponent" id="blog" classs="blog">
    <div class="blog-container pc_padding m_set_width">
      <h1 class="m-b-30 head-1">
        What does the Bible say about judging others
      </h1>

      <div class="autor m-b-30">
        <div>Author: Irene </div>
        <div>Publish: 2025/1/3 </div>
      </div>

      <AdComponent
        v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
        ref="ads-blog-1" class="ads-insert-max m-t-40" :ads="adsensConfig?.blog"
        :showDebug="showDebug" />

      <p>
        In the Bible, there are many people who have experienced suffering. They showed admirable
        tenacity and courage in difficult situations. With their faith and strength, they turned
        suffering into grace, making life and living more abundant and meaningful. Next, let us
        learn how they faced suffering, got out of difficulties, and made life better.
      </p>

      <h2>Ruth</h2>

      <p>
        In the Old Testament, after Ruth&#39;s husband died, she became a young widow without
        children. The mother-in-law planned to return to Judah and persuaded Ruth to stay and
        remarry in order to seek happiness. But Ruth insisted on staying with her mother-in-law. She
        firmly said to her mother-in-law: &quot;Don&#39;t urge me to go back. Where you go, I will
        go there; your country is my country, and your God is my God. Where you die, I will die
        there, unless death can separate you and me.&quot; So they returned to Judah. Ruth made a
        living by gleaning, and Boaz also took care of her because of her virtue. With the help of
        her mother-in-law, Boaz married Ruth and gave birth to a son, who later became David&#39;s
        grandfather. Ruth&#39;s story is very inspiring. In desperation, she chose to follow her
        mother-in-law. What sustained her through adversity? Before answering this question, let’s
        look at the story of another woman in the Bible for more inspiration.
      </p>

      <p>
        <img alt="img"
          src="@/assets/YzAzMjNjZmE1OWNkN2E5MDk1YmFiMDQzZjM1Yzg1ZjZfTnNLdVcxNnNFMDVkYTkxQjNMWXBqaWZYQVZ3bGtlTU9fVG9rZW46T2lDTGJUWHpUb3lmNW14S3J3eWNvUTlnbkZkXzE3MzMzODE3OTA6MTczMzM4NTM5MF9WNA.png">
      </p>

      <h2>Esther</h2>

      <p>
        In the Book of Esther, beautiful Esther became queen and lived a rich life. Later, her
        people faced extinction. Her adoptive father Mordecai urged her to go to the king to save
        them, but going to the king without a summons meant death. Unsure whether the king still
        loved her, Esther hesitated. Esther told Mordecai about her concerns, and Mordecai reminded
        her: How do you know that you got the position of queen for this opportunity? So Esther
        listened to Mordecai’s advice and decided to go to the king in violation of the rules. She
        said to Mordecai: &quot;Let the people fast for me for three days and three nights, and then
        I will go in to see the king in violation of the rules. If I die, I die.&quot; After praying
        and fasting, she took the risk. When the king saw her, he held out his scepter and saved her
        life. The king agreed to her request, thwarted the enemy’s plan, and saved her people.
        Esther was respected by the king and her people for her bravery and selflessness.
      </p>

      <h2>The secret to facing hardship</h2>

      <ul class="in-page-ul">
        <li>
          <p>
            Both Ruth and Esther experienced desperate situations. They did not complain in
            difficult situations, nor were they afraid of the environment. They were willing to give
            up their own happiness and lives to seek benefits for others. In the end, they both got
            out of the difficult situation, made their lives and lives more abundant, and were
            respected by people. From what Ruth said to her mother-in-law in difficult situations
            and what Esther said to Mordecai in difficult situations, we can see that they have firm
            faith, which is a secret for them to face difficulties bravely and calmly.
          </p>
        </li>
        <li>
          <p>
            In addition, they all have clear goals and directions in desperate situations, have a
            strong sense of responsibility, and know what choices they should make in their
            positions. This is also a reason why they can face suffering bravely. In desperate
            situations, Ruth saw that her mother-in-law was old and needed care. If she was allowed
            to return to her hometown alone, her life would be unimaginable. In the face of life and
            death, Esther saw that her compatriots would be killed and lose their lives. As a queen,
            she had the responsibility to save them. In addition, the secret of their ability to
            face suffering calmly is: despise suffering. In the face of suffering, Ruth considered
            the safety of her mother-in-law more important than her own happiness. She said,
            &quot;Only death can separate you and me.&quot; Esther considered the lives of her
            compatriots more important than her own life. A strong sense of responsibility made her
            say, &quot;If I die, I die.&quot; It is because of their selflessness and noble
            character that they were favored by fate and gained unexpected happiness and peace.
          </p>
        </li>
      </ul>

      <p>
        <img alt="img"
          src="@/assets/MGRjNmYxNjNkYTJlMjVhMzMwNTFkY2Y2MDRjYzhhZTlfZFF3VkFzME9LV0x3ckx1Tjh3eXc0ck1vSWRKbXJ0VXpfVG9rZW46SndzNmJ1Q1Y3bzlkbkx4cnVmUGM5ZW9lblJ0XzE3MzMzODE3OTA6MTczMzM4NTM5MF9WNA.png">
      </p>

      <h2>Conclusion</h2>

      <p>
        In the Bible, there are many stories of characters who turned suffering into grace, such as
        Joseph and Job. They all experienced great suffering, but in the face of difficulties, they
        did not complain about the world or fear suffering. Joseph became the prisoner of Egypt
        because of suffering. He not only brought welfare to many people, but also won honor and
        peace for himself in the end. After experiencing suffering, Job received double blessings
        and said with emotion, &quot;I have heard of you by the hearing of the ear, but now I see
        you with my own eyes.&quot; His understanding and insight are admirable.
      </p>

      <p>
        Through their stories, we understand that suffering is an opportunity for growth and
        training, a necessary path to a higher realm, and grace is often hidden in suffering.
        Suffering can temper our character and strengthen our will. Therefore, no matter how
        difficult our situation is, we must firmly believe that there will be light after suffering.
        We must remain upright and brave, learn to face suffering calmly, and make wise choices in
        suffering. At the same time, we must also learn to endure suffering, let suffering become
        our driving force for progress, and make the suffering we experience meaningful and
        valuable.
      </p>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
      ref="ads-blog-1" class="pc_padding m-t-40" :ads="adsensConfig?.blog" :showDebug="showDebug" />

    <div class="blog-list-container ">
      <div class="blog-list-title">
        Recommend Reading
      </div>
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <a href="/blogs">
      <div class="blog-all-btn cursor">
        View All
      </div>
    </a>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/blog_base.scss';

export default {
  name: 'Blog3',
  components: {
    AdComponent,
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
      card: [
        {
          img: require('@/assets/blog-1.png'),
          title: 'Seven Powerful Ways of Thinking in the Bible',
          desc: "German writer Goethe said that all my profound thoughts come from my belief in the Bible; my moral life and literary works are all guided by the Bible. The Bible is the most reliable and powerful capital in my life, and it is really an inexhaustible treasure house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of understanding and have lively hope in life.",
          routename: 'seven-powerful-ways-of-thinking-in-the-bible',
        },
        {
          img: require('@/assets/blog-2.png'),
          title: 'Greed in the Bible',
          desc: "The concept of coveting in the Bible is highly significant for understanding its morality and ethics. Essentially, coveting is having a strong longing for what belongs to others. This simple yet profound concept, deeply rooted in Biblical teachings like the Ten Commandments, reveals much about human nature and spiritual expectations.In this article, we will take a deeper look at greed as described in the Bible.",
          routename: 'greed-in-the-bible',
        },
        {
          img: require('@/assets/blog-3.png'),
          title: 'What does the Bible say about judging others',
          desc: "In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?",
          routename: 'what-does-the-bible-say-about-judging-others',
        },

      ],
    };
  },
  computed: {
    title () {
      return `What does the Bible say about judging others | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (routername) {
      window.location.href = `/${routername}`
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos].indexOf(position) : this.adPosConfig?.[`${pos}PC`].indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
